<template>
  <div class="uploadFile second-level">
    <BackTitle>上传文件 </BackTitle>
    <div class="second-content">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForms">
        <el-form-item label="所属分组" prop="groupId">
          <div class="check-group">
            <GlobalTreeSelect
              style="width: 377px"
              v-model="dataForm.groupId"
              filterable
              nodeKey="value"
              :treeProps="defaultProps"
              :data="treeData"
              @input="_getBelongGroup"
              @deptAllInfo="_getBelongDeptAllInfo"
            >
            </GlobalTreeSelect>
            <div class="text-btn-sm cu-p" @click.stop="_addGroupHandle" :style="[{'color':$store.state.settings.theme},{'margin-left':'15px'}]">添加分组</div>
          </div>
        </el-form-item>
        <el-form-item label="可见范围" prop="useType" v-if="$route.query.pageType === 'knowledgeBase'">
          <!-- 可见范围 -->
          <VisibilityRange
          pageType="file"
          :courseInfo="dataForm"/>
        </el-form-item>
        <el-upload
          name="files"
          drag
          :headers="uploadHeader"
          :action="uploadAction"
          :on-success="_handleSuccess"
          :on-progress="progress"
          multiple
          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .doc,.docx, .xls, .xlsx, .ppt, .pdf, .mp4,.pptx"
          :file-list="fileList"
        >
          <img class="upload-img" src="@/assets/img/upload-drag.png" alt="" />
        </el-upload>
        <div class="upload-hint">
          <p>严禁上传包含色情、暴力、反动等相关违法信息的文件.</p>
          <p>支持 Word/Excel/PPT/Pdf格式文档类课件，单个 1GB 以内</p>
          <p>支持JPG/JPEG/PNG格式图像类课件，单个20MB以内</p>
          <p>支持 mp4 格式视频类课件，单个3GB以内</p>
        </div>
        <div class="file-list">
          <div class="file-item" v-for="(item, index) in dataForm.fileList" :key="index">
            <div class="file-icon">
              <img src="@/assets/img/video.png" alt="" />
            </div>
            <div class="file-content">
              <div class="text">
                <div>{{ item.name }}</div>
                <div>{{ (Math.floor(item.percentage)) + "%" }}</div>
              </div>
              {{item.isShowSuccess}}
              <div class="progress">
                <el-progress
                  v-if="item.percentage<100"
                  :percentage="item.percentage"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
            <div class="right-icon" @click="imgDelete(item,index)">
              <img v-if="item.percentage == 100" class="success" src="@/assets/img/upload-success-icon.png" alt="">
              <img
                class="delete"
                src="@/assets/img/upload-delete-icon.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </el-form>
      <div class="fx-cen mt38">
        <el-button @click="_back">取消</el-button>
        <el-button class="add-group-btn"  :style="{'--lock-btn': $store.state.settings.theme}" :disabled="uploadLoadFlag" @click="defineResume('again')"
          >保存并继续上传</el-button
        >
        <el-button type="primary" @click="defineResume" :disabled="uploadLoadFlag">{{uploadLoadText?'保存':'转码中，请稍等'}}</el-button>
      </div>
    </div>
    <UploadFileTips
      v-if="uploadFileTipsShow"
      :uploadFileTipsShow="uploadFileTipsShow"
    />
   <!-- 资源分组添加 -->
   <AddGroup
      pageType="sourceGroup"
      v-if="addGroupShow"
      @submiAddGroup="_submiAddGroup"
      :defaultProps="defaultProps"
      :treeData="treeData"
      :addGroupShow="addGroupShow"
      nodeKey="value"
      ref="addGroup"
    ></AddGroup>
  </div>
</template>
<script>
import BackTitle from '@/components/backTitle/index.vue'
import UploadFileTips from './components/uploadFileTips.vue'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import VisibilityRange from '@/components/visibilityRange/index.vue'
import { getCookie } from '@/utils/util'
import { getTreeApi, uploadSourseFileApi, addAndEditTreeApi } from '@/api/resource'
import {
  getTreeListApi,
  batchAdditionAPi,
  addTreeApi
} from '@/api/knowledge'
import fileType from '@/mixins/fileType'
import AddGroup from '@/components/addGroup/index.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  mixins: [fileType],
  components: {
    BackTitle,
    UploadFileTips,
    GlobalTreeSelect,
    VisibilityRange,
    AddGroup
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeData: [],
      dataForm: {
        groupId: '',
        fileList: [],
        useUserIds: [],
        useDeptIds: [],
        useType: 2
      },
      dataRule: {
        groupId: {
          required: true,
          message: '请选择分组',
          trigger: ['blur']
        },
        useType: {
          required: true,
          message: '请选择可见范围',
          trigger: ['blur']
        }
      },
      url: '',
      uploadHeader: {
        token: getCookie('token')
      },
      uploadAction:
        process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload/big/file?toPdfFlag=True', // 上传附件 链接
      percent: '0',
      uploadFileTipsShow: false,
      fileList: [],
      uploadLoadFlag: true,
      uploadLoadText: true,
      addGroupShow: false// 资源分组

    }
  },
  computed: {
    ...mapState('fileUpload', ['resourseFilesObj', 'koneledgeBaseFilesObj', 'disableUpload'])
  },
  watch: {
    disableUpload (val) {
      console.log(val, '000000000000000')
      if (val.code === 200) {
        this.uploadLoadFlag = false
        this.uploadLoadText = true
      }
    }
  },
  created () {
    if (this.$route.query.groupId) {
      this.dataForm.groupId = this.$route.query.groupId
    }
    this.getTree()
  },
  mounted () {
    console.log(this.disableUpload, '---------------')

    if (this.$route.query.pageType === 'knowledgeBase') {
      this.dataForm = this.koneledgeBaseFilesObj
    } else if (this.$route.query.pageType === 'resource') {
      this.dataForm = this.resourseFilesObj
    }
    console.log(this.koneledgeBaseFilesObj, this.resourseFilesObj, '====')
    this.CHANGEISSHOWRIGHTFILES(false)
    // this.CHANGEISSHOWRIGHTFILES(false)
  },
  methods: {
    ...mapMutations('fileUpload', ['CHNAGEREOURCEFILESOBJ', 'CHANGEKONWLEDGEBASEFILESOBJ', 'CHANGEISSHOWRIGHTFILES', 'CHNAGEDISABLEUPLOAD']),
    _addGroupHandle () {
      // 资源分组添加
      this.addGroupShow = true
    },
    _submiAddGroup (item) {
      // 资源分组提交
      const groupNameArr = []
      item.groupNameList.forEach((item) => {
        groupNameArr.push(item.groupName)
      })
      const obj = {
        ...item,
        groupNames: groupNameArr
      }
      const api = this.$route.query.pageType === 'resource' ? addAndEditTreeApi : addTreeApi
      api(obj).then((res) => {
        if (res.code === 200) {
          this.addGroupShow = false
          this.treeData = []
          this.getTree()
        }
      })
    },
    getTree () {
      // 获取资源分组树形结构
      if (this.$route.query.pageType === 'resource') {
        getTreeApi({
          parentId: '0'
        }).then((res) => {
          if (res.code === 200) {
            this.treeData = res.data
          }
        })
      } else {
        getTreeListApi({
          parentId: '0'
        }).then((res) => {
          if (res.code === 200) {
            this.treeData = res.data
          }
        })
      }
    },
    _getBelongDeptAllInfo (data) {},
    // 所属分组--取值
    _getBelongGroup (value) {
    },
    _selectDept (item) {
      this.dataForm.useDeptId = item.id
      this.dataForm.useDeptName = item.name
    },
    defineResume (type) {
      // 保存并继续
      for (let i = 0; i < this.dataForm.fileList.length; i++) {
        if (Number(this.dataForm.fileList[i].percentage) < 100) {
          this.$message.error('文件还未上传完！')
          return
        }
      }
      this.$refs.dataForms.validate((valid) => {
        if (valid) {
          if (this.$route.query.pageType === 'knowledgeBase' &&
           this.dataForm.useType == 2 &&
           (!(this.dataForm.useUserIds && this.dataForm.useUserIds.length) &&
            !(this.dataForm.useDeptIds && this.dataForm.useDeptIds.length))) {
            this.$message.error('请选择可见范围！')
            return
          }
          if (!this.dataForm.fileList.length) {
            this.$message.error('请选择上传文件！')
            return
          }
          this.uploadFile(type)
        }
      })
    },
    uploadFile (type) {
      if (this.$route.query.pageType === 'resource') {
        const fileList = []
        this.dataForm.fileList.forEach(item => {
          if (item.response) {
            fileList.push({
              fileName: item.name,
              fileType: this.matchType(item.name),
              size: item.size,
              fileDuration: item.response.data[0].fileDuration,
              sourceFileUrl: item.response.data[0].url,
              percentage: item.percentage,
              fileSuffix: item.response.data[0].url.substring(item.response.data[0].url.lastIndexOf('.') + 1)
            })
          }
        })

        uploadSourseFileApi({ ...this.dataForm, fileList }).then(res => {
          if (res.code === 200) {
            this.$message.success('上传成功！')
            this.CHNAGEREOURCEFILESOBJ({})
            // this.CHANGEISSHOWRIGHTFILES(false)
            if (type !== 'again') {
              this.$router.back()
            }
            this.dataForm = {
              groupId: '',
              fileList: [],
              useType: 2
            }
            this.fileList = []
          }
        })
      } else {
        const useUserId = []
        const useDeptId = []
        const viewDeptNames = []
        const viewUserNames = []
        console.log(this.dataForm, '===')
        this.dataForm.useUserIds && this.dataForm.useUserIds.length && this.dataForm.useUserIds.forEach(item => {
          useUserId.push(item.id)
          viewUserNames.push(item.name)
        })
        this.dataForm.useDeptIds && this.dataForm.useDeptIds.length && this.dataForm.useDeptIds.forEach(item => {
          useDeptId.push(item.id)
          viewDeptNames.push(item.name)
        })
        const fileList = []
        this.dataForm.fileList.forEach(item => {
          if (item.response) {
            fileList.push({
              fileName: item.name,
              fileType: this.matchType(item.name),
              size: item.size,
              fileDuration: item.response.data[0].fileDuration,
              sourceFileUrl: item.response.data[0].url,
              percentage: item.percentage

            })
          }
        })

        batchAdditionAPi({
          ...this.dataForm,
          fileList,
          viewUserIds: useUserId.length ? useUserId.toString() + ',' : '',
          viewDeptIds: useDeptId.length ? useDeptId.toString() + ',' : '',
          viewUserNames: viewUserNames.length ? viewUserNames.toString() : '',
          viewDeptNames: viewDeptNames.length ? viewDeptNames.toString() + ',' : ''
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('上传成功！')
            this.CHANGEKONWLEDGEBASEFILESOBJ({})
            // this.CHANGEISSHOWRIGHTFILES(false)
            if (type !== 'again') {
              this.$router.back()
            }
            this.dataForm = {
              groupId: '',
              useUserIds: [],
              useDeptIds: [],
              fileList: [],
              useType: 2
            }
            this.fileList = []
          }
        })
      }
    },
    _handleSuccess (response, file, fileList) {
      console.log(response, '===')
      this.CHNAGEDISABLEUPLOAD(response)
      if (response.code == 200) {
        const arr = []
        fileList.forEach(item => {
          if (item.response && item.response.code === 200) {
            arr.push({
              ...item,
              percentage: 100
            })
          }
        })
        this.dataForm.fileList = arr
        this.uploadLoadFlag = false
        this.uploadLoadText = true
      } else {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.dataForm.fileList = arr
        this.fileList = arr
        this.$message.error(response.message)
      }
    },

    progress (event, file, fileList) {
      this.dataForm.fileList = fileList.map(item => {
        return {
          ...item
        }
      })
      this.fileList = fileList
      this.uploadLoadFlag = true
      this.uploadLoadText = false
    },
    imgDelete (item, index) {
      this.dataForm.fileList.splice(index, 1)
      this.fileList = this.dataForm.fileList
    },
    _back () {
      this.$confirm('文件正在上传中，是否继续后台上传?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消上传',
        type: 'warning'
      }).then((res) => {
        console.log(res)
        if (res === 'confirm') {
          if (this.$route.query.pageType === 'knowledgeBase') {
            this.CHANGEKONWLEDGEBASEFILESOBJ(this.dataForm)
          } else if (this.$route.query.pageType === 'resource') {
            this.CHNAGEREOURCEFILESOBJ(this.dataForm)
          }
          this.CHANGEISSHOWRIGHTFILES(true)
          this.$router.back()
        }
      }).catch((res) => {
        console.log(res)
        if (res === 'cancel') {
          this.$router.back()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.mt38 {
  margin-top: 38px;
}
.second-content{
  width: 780px;
  height:  calc(100vh - 240px);
  overflow-y: auto;
}
.second-content /deep/ .el-upload {
  width: 750px;
  height: 220px;
  .upload-img {
    width: 743px;
    height: 220px;
  }
}
/deep/ .el-upload-dragger {
  width: 752px;
  height: 220px;
  border: none;
}
/deep/ .el-upload--text {
  border: none;
}
.upload-hint {
  color: #9a9a9a;
  font-size: 12px;
  margin: 10px 0;
}
.file-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.file-item {
  width: 320px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d6d9e5;
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 12px;
  // margin: 5px;
  margin: 5px;
  position: relative;

  .right-icon {
    .success {
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
    }
    .delete {
      display: none;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 14px;
      height: 14px;

    }
  }
  &:hover .delete{
    display: block ;
  }
  &:hover .success{
    display: none;
  }
  .file-icon {
    margin-right: 8px;
    img {
      width: 38px;
      height: 38px;
    }
  }
  .file-content {
    flex: 1;
    .text {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      font-size: 14px;
      div:nth-child(1){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .progress {
      margin-top: 4px;
    }
  }
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-upload-list {
  display: none;
}
.upload-hint{
  margin-left: 5px;
}
.check-group{
  display: flex;
  align-items: center;
}
</style>
