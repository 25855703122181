/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-21 19:01:35
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-07 13:41:27
 */
import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  // 获取资源管理列表
  getResourcelist: path + '/sourceFile/findByPage',
  // 获取分组树形列表
  getTree: path + '/sourceGroup/findTree',
  // 新增&修改分组树
  addAndEditTree: path + '/sourceGroup/createOrUpdate',
  // 删除树
  deleteTree: path + '/sourceGroup/remove',
  // 文件上传
  uploadSourseFile: path + '/sourceFile/addBath',
  // 文件修改
  fileEdit: path + '/sourceFile/edit',
  // 文件删除
  fileDelete: path + '/sourceFile/remove',
  // 文件预览
  getFilePreview: path + '/sourceFile/preview'
}
export const getResourcelistApi = (data) => post(API.getResourcelist, data)
export const getTreeApi = (data) => get(API.getTree, data)
export const addAndEditTreeApi = (data) => post(API.addAndEditTree, data)
export const deleteTreeApi = (data) => get(API.deleteTree, data)
export const uploadSourseFileApi = (data) => post(API.uploadSourseFile, data)
export const fileEditApi = (data) => post(API.fileEdit, data)
export const fileDeleteApi = (data) => post(API.fileDelete, data)
export const getFilePreviewApi = (data) => get(API.getFilePreview, data)
