import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  getTreeList: path + '/knowledgeGroup/findTree', // 获取分组
  deleteTree: path + '/knowledgeGroup/remove', // 删除分组
  editTree: path + '/knowledgeGroup/edit', // 修改分组
  addTree: path + '/knowledgeGroup/createOrUpdate', // 新增分组
  getPageList: path + '/knowledgeFile/findByPage', // 获取列表
  editList: path + '/knowledgeFile/edit', // 列表编辑
  batchAddition: path + '/knowledgeFile/addBath', // 批量新增
  batchDelete: path + '/knowledgeFile/remove', // 批量删除
  getDetail: path + '/knowledgeFile/detail'// 获取详情
}
export const getTreeListApi = (data) => get(API.getTreeList, data)
export const deleteTreeApi = (data) => post(API.deleteTree, data)
export const editTreeApi = (data) => post(API.editTree, data)
export const addTreeApi = (data) => post(API.addTree, data)
export const getPageListApi = (data) => post(API.getPageList, data)
export const editListApi = (data) => post(API.editList, data)
export const batchAdditionAPi = (data) => post(API.batchAddition, data)
export const batchDeleteAPI = (data) => post(API.batchDelete, data)
export const getDetailAPI = (data) => get(API.getDetail, data)
