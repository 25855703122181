<template>
    <el-dialog
      :visible.sync="uploadFileTipsShow"
      :before-close="cancel"
      :close-on-click-modal="false"
      title="提示"
      width="400px"
    >
      <div slot="title" class="header-title">
        <svg
          t="1700015762243"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3163"
          width="19.2"
          height="19.2"
        >
          <path
            d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024zM460.8 278.755556a51.2 51.2 0 1 1 102.4 0v295.822222a51.2 51.2 0 1 1-102.4 0V278.755556z m102.4 455.111111a51.2 51.2 0 1 1-102.4 0 51.2 51.2 0 0 1 102.4 0z"
            fill="#FAAD14"
            p-id="3164"
          ></path>
        </svg>
        <span>提示</span>
      </div>
      <div class="center">
        <div class="tips">成功<span style="color: #10C038;">2</span>,失败<span style="color: #F04134;">1</span>条</div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="文件名" width="180">
          </el-table-column>
          <el-table-column prop="name" label="状态" width="180">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submitHandle()">确认</el-button>
      </div>
    </el-dialog>
  </template>
<script>
export default {
  props: {
    uploadFileTipsShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableData: [],
      dataForm: {
        name: 123
      }
    }
  },
  methods: {
    cancel () {
      this.$parent.uploadFileTipsShow = false
    },
    submitHandle () {
      this.uploadFileTipsShow = false
    }
  }
}
</script>
  <style lang="scss" scoped>
  /deep/.el-dialog__body {
    padding-top: 10px !important;
  }
  .header-title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .center {
    .tips {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
    }
  }
  </style>
