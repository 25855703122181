<template>
  <el-dialog
    :visible.sync="addGroupShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="添加分组"
    width="450px">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
      @keyup.enter.native="_submiAddGroup()">
      <el-form-item label="所属分组">
        <GlobalTreeSelect
          style="width: 275px"
          v-model="dataForm.groupId"
          :nodeKey="nodeKey"
          filterable
          :treeProps="defaultProps"
          :data="treeData"
          @input="_getBelongGroup"
          @deptAllInfo="_getBelongDeptAllInfo">
        </GlobalTreeSelect>
      </el-form-item>
      <el-form-item label="可见范围" prop="useType" v-if="isOneLevel">
        <el-radio-group v-model="dataForm.useType">
          <el-radio :label="2">可见范围</el-radio>
          <el-radio :label="1">全部</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="useDeptId" v-if="dataForm.useType==2&&isOneLevel">
        <el-select filterable value-key="id" clearable v-model="dataForm.useDeptId" placeholder="请选择" style="width: 275px">
          <el-option
            v-for="item in visibleDeptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="_selectDept(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-for="(item, index) in dataForm.groupNameList"
        :key="index"
        :label="index == 0 ? '分组名称' : ''"
        :prop="'groupNameList.' + index + '.groupName'"
        :rules="{
          required: true,
          message: '请输入分组名称',
          trigger: ['change'],
        }">
        <div class="add-group-name">
          <el-input v-model="item.groupName" style="width:270px" placeholder="请输入"></el-input>
          <i
          v-if="dataForm.groupNameList.length>1"
          @click="_deleteGroupList(index)"
          style="color:#F04134;"
          class="iconfont icon-icon_delete"></i>
          <!-- <img
           v-if="dataForm.groupNameList.length>1"
           style="width:20px;height:20px;"
            src="@/assets/img/group-delete-red.png"
            alt=""
            @click="_deleteGroupList(index)"
          /> -->
        </div>
      </el-form-item>
      <div class="add-group">
        <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" round @click="_addGroupName">+ 添加分组</el-button>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submiAddGroup()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { getManageDeptListApi } from '@/api/api'
export default {
  components: { GlobalTreeSelect },
  props: {
    addGroupShow: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visibleDeptList: [], // 可见部门列表
      isOneLevel: true, // 一级标题展示可见范围字段
      visibleDeptProps: {
        label: 'name',
        children: 'childList'
      },
      dataForm: {
        groupId: null,
        useType: null, // 可见范围
        groupNameList: [
          {
            groupName: ''
          }
        ], // 分组名称
        useDeptId: null,
        useDeptName: null
      },
      dataRule: {
        groupId: [
          { required: true, message: '请选择所属分组', trigger: ['change', 'blur'] }
        ],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ]
      },
      groupParentInfo: {}
    }
  },
  created () {
    this._getManageDeptList()
  },
  methods: {
    cancel () {
      this.$parent.addGroupShow = false
    },
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    _submiAddGroup () {
      // 分组保存
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.dataForm,
            useType: this.groupParentInfo.useType || this.dataForm.useType,
            useDeptId: this.groupParentInfo.useDeptId || this.dataForm.useDeptId,
            useDeptName: this.groupParentInfo.useDeptName || this.dataForm.useDeptName

          }
          this.$emit('submiAddGroup', params)
        }
      })
    },
    // 点击节点的响应
    _handleNodeClick (node) {
      this.dataForm.parentId = node.id
      this.$refs.selectTree.blur()
    },
    _addGroupName () {
      // 添加分组名称
      this.dataForm.groupNameList.push({
        groupName: ''
      })
    },
    _deleteGroupList (index) {
      this.dataForm.groupNameList.splice(index, 1)
    },
    // 所属分组-部门信息
    _getBelongDeptAllInfo (data) {
      if (data) {
        // 当所属分组选择的不是一级分组时--可见范围跟父级保持一致
        this.isOneLevel = false
        if (this.pageType === 'sourceGroup') {
        // 区分分组数据类型
          this.groupParentInfo = data.sourceGroup
        } else {
          this.groupParentInfo = data
        }
      } else {
        this.isOneLevel = true
      }
    },
    // 所属分组--取值
    _getBelongGroup (value) {
      this.dataForm.parentId = value || 0
    },
    _selectDept (item) {
      this.dataForm.useDeptId = item.id
      this.dataForm.useDeptName = item.name
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px;
  text-align: center;
  padding: 8px 9px;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.add-group-name {
  // margin-bottom: 10px;
  width: 298px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    flex: 1;
  }
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}
.add-group-btn {
  width: 130px;
  // border: 1px solid #0089ff;
  font-size: 14px;
  border-color:var(--lock-btn)!important;
  color:var(--lock-btn)!important;
}
</style>
